import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import "./style/index.css";
import {SignupPage, SigninPage} from "./pages";


const Home = props => {
    console.log("rotta Home");
    console.log( props );
    return (
        <div className="App-header">
          <img
            src={'/logo_white'}
        />
        </div>
    )
};


const NotFound = props => (<div>Not Found</div>);



const allPublicFreeRoutes = createBrowserRouter([
    {
        exact: true,
        path: "/",
        element: <Home/>
    }
]);


function App() {
  return (
    <RouterProvider
        router={ allPublicFreeRoutes }
    />
  );
}

export default App;
